.Card {
    width: 100px;
    height: 100px;
    border: 10px solid rgba(0, 0, 0, 1);
    position: relative;
}

.selected {
    border-color: green;
}

.up {
    position: absolute;
    margin: auto;
    font-size: 16px;
    transform: translateX(-50%) translateY(-50%) rotate(0deg);

    margin-left: auto;
    margin-right: auto;
    text-align: center;

    top: 10%;
    left: 50%;
}

.right {
    position: absolute;
    margin: auto;
    font-size: 16px;
    transform: translateX(-50%) translateY(-50%) rotate(90deg);

    margin-top: auto;
    margin-bottom: auto;
    text-align: center;

    top: 50%;
    left: 90%;
}

.down {
    position: absolute;
    margin: auto;
    font-size: 16px;
    transform: translateX(-50%) translateY(-50%) rotate(180deg);

    margin-left: auto;
    margin-right: auto;
    text-align: center;

    top: 90%;
    left: 50%;
}

.left {
    position: absolute;
    margin: auto;
    font-size: 16px;
    transform: translateX(-50%) translateY(-50%) rotate(270deg);

    margin-top: auto;
    margin-bottom: auto;
    text-align: center;

    top: 50%;
    left: 10%;
}

.clockwise-rotate {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 16px;

    top: 30%;
    right: 20%;
}

.counter-clockwise-rotate {
    position: absolute;
    margin: 0;
    padding: 0;
    font-size: 16px;
    transform: scale(-1, 1);

    top: 30%;
    left: 20%
}