.Board {
    display: grid;
    grid-template-rows: repeat(4, 118px);
    grid-template-rows: repeat(4, 118px);

}

.topper {
    grid-column: 2 / 4;
    /* span from grid column line 1 to 3 (i.e., span 2 columns) */
    grid-row: 1 / 2;

    margin: auto;
}

.lefter {
    grid-column: 1 / 2;
    grid-row: 2 / 4;

    margin: auto;
}

.lefterText {
    transform: rotate(270deg);
}

.righter {
    grid-column: 4 / 5;
    grid-row: 2 / 4;

    margin: auto;
}

.righterText {
    transform: rotate(90deg);
}

.bottomer {
    grid-column: 2 / 4;
    grid-row: 4 / 5;

    margin: auto;
}

.space {
    /*min-width: 100px;
    min-height: 100px;*/
}